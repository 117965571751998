import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'Services/carousel/core__carousel';
import PubSub from 'Services/pubsub/core__pubsub';
import { getComponent } from 'Services/core__imports';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { carouselSettings as carouselSettings_LOCAL } from '../../helper/carouselAppConfig';
import { carouselSettings as carouselSettings_CORE } from 'CASINO_CORE__UI/apps/GamesCarouselApp/core__games-carousel-config';
import {
  BT,
  BD,
  MD as MD_LOCAL,
  OB,
  CB,
  LB,
  TP,
  W,
  PC as PC_LOCAL,
  CBD as CBD_LOCAL,
  CarouselWrap as CarouselWrap_LOCAL,
} from 'CASINO_UI/apps/GamesCarouselApp/GamesCarousel';
import {
  CL,
  MI,
  MD as MD_CORE,
  PC as PC_CORE,
  TsAndCs,
  CBD as CBD_CORE,
  CarouselWrap as CarouselWrap_CORE,
} from 'CASINO_CORE__UI/apps/GamesCarouselApp/core__gamesCarousel';
const PC = getComponent(PC_LOCAL, PC_CORE);
const MD = getComponent(MD_LOCAL, MD_CORE);
const CBD = getComponent(CBD_LOCAL, CBD_CORE);
const CarouselWrap = getComponent(CarouselWrap_LOCAL, CarouselWrap_CORE);
const carouselSettings = getComponent(
  carouselSettings_LOCAL,
  carouselSettings_CORE
);
/**
 * this component contains game carousel
 * @param {*} getCasinoBanners fucntion call in initial load
 * @param {*} casinoBannerData array to get casino banners
 * @param {*} loadGamePage fucntion to load game page
 * */

class GamesCarouselPage extends React.Component {
  constructor() {
    super();
    this.state = {
      carouselArrowState: false,
      isAuthenticated: false,
      carouselData: [],
      userSegment: '',
    };
  }

  componentDidMount() {
    this.props.getCasinoBanners();
    this.setAuthenticationStatus();
    this.getAllCasinoBanners();
  }

  componentWillUnmount() {
    //Unsubscribing from PubSubs
    this.sessionCreated?.unsubscribe();
    this.sessionUpdated?.unsubscribe();
    this.sessionDestroy?.unsubscribe();
  }

  setAuthenticationStatus() {
    // when user login
    this.sessionCreated = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const UserAuthData = FSBCustomerSession.getSession();
      if (UserAuthData && UserAuthData['accessToken']) {
        this.setState({
          isAuthenticated: true,
          userSegment: UserAuthData.segmentRef,
        });
      }
    });
    // when user relogin or reload page
    this.sessionUpdated = PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      const UserAuthData = FSBCustomerSession.getSession();
      if (UserAuthData && UserAuthData['accessToken']) {
        this.setState({
          isAuthenticated: true,
          userSegment: UserAuthData.segmentRef,
        });
      }
    });
    // if user is already login
    const UserAuthData = FSBCustomerSession.getSession();
    if (UserAuthData && UserAuthData['accessToken']) {
      this.setState({
        isAuthenticated: true,
        userSegment: UserAuthData.segmentRef,
      });
    }
    // handle when user logout
    this.sessionDestroy = PubSub.listen(PubsubEvents.SESSION_DESTROY, () => {
      this.setState({ isAuthenticated: false });
    });
  }

  getAllCasinoBanners() {
    const getAllCarouselData = [];
    const getAllAtributes = document.querySelectorAll(
      '[data-app="GamesCarouselApp"]'
    );
    for (let c = 0; c < getAllAtributes.length; c++) {
      const params = getAllAtributes[c]?.dataset;
      getAllCarouselData.push({
        autoplay: params?.autoplay,
        infinite: params?.infinite,
        speed: params?.speed,
      });
    }

    this.setState({
      carouselData: [...getAllCarouselData],
    });
  }

  onActionClick(event, link) {
    event.stopPropagation();
    window.location.href = window.location.origin + '/' + link;
  }
  handleMoreInfo(event, link) {
    event.stopPropagation();
    window.location.href = window.location.origin + '/' + link;
  }

  getFilteredItemsByAuth = items =>
    items?.filter(itm => {
      const authProp = itm?.auth;
      if (
        (authProp === GLOBAL_CONSTANTS.AUTH && !this.state.isAuthenticated) ||
        (authProp === GLOBAL_CONSTANTS.NOAUTH && this.state.isAuthenticated)
      ) {
        return false;
      }
      //to filter banners based on user segment
      //visibletohavingsegment is a string containing comma seperated list of segment
      if (itm.visibletohavingsegment) {
        if (this.state.userSegment) {
          const segmentsAllowed = itm.visibletohavingsegment.split(',');
          if (segmentsAllowed.includes(this.state.userSegment)) {
            return true;
          }
        }
      } else {
        return true;
      }
    }) ?? [];

  render() {
    const casinoBannerDataFiltered = this.getFilteredItemsByAuth(
      this.props?.casinoBannerData ?? []
    );
    const settings = carouselSettings(this.props);
    const newSetting = this.state.carouselData.length > 0 && {
      ...settings,
      autoplay: this.state.carouselData[0].autoplay
        ? this.state.carouselData[0].autoplay
        : true,
      infinite: this.state.carouselData[0].infinite
        ? this.state.carouselData[0].infinite
        : true,
      speed: this.state.carouselData[0].speed
        ? Number(this.state.carouselData[0].speed)
        : 500,
    };

    return (
      <OB id="pageScrollToTop" data-test={'test-hover'}>
        <Carousel {...newSetting} arrows={true} width="100%">
          {casinoBannerDataFiltered &&
            casinoBannerDataFiltered?.map((banner, ind) => {
              return (
                <CarouselWrap key={ind} style={{ position: 'relative' }}>
                  <TP
                    image-test={ind === 0 && 'tab-image'}
                    onClick={() =>
                      this.props.loadGamePage(banner?.adlinkurl, banner?.gameId)
                    }
                  >
                    <CB imageurl={banner.image}>
                      <MD>
                        <CBD></CBD>
                        <W>
                          <CL>
                            {banner?.tagsenabled &&
                              banner?.tagstodisplay?.map((tag, tInd) => {
                                return <LB key={tInd}>{tag}</LB>;
                              })}
                          </CL>
                          <BT>{banner.title}</BT>
                          <BD>{banner.description}</BD>
                          {banner.olympic && (
                            <MI
                              onClick={event =>
                                this.handleMoreInfo(event, banner?.moreInfoLink)
                              }
                            >
                              <PC
                                onClick={event =>
                                  this.onActionClick(event, banner?.playLink)
                                }
                              >
                                {banner?.playLabel}
                              </PC>
                              <React.Fragment>
                                {banner?.moreInfoLabel}
                              </React.Fragment>
                            </MI>
                          )}
                        </W>
                      </MD>
                    </CB>
                  </TP>
                  {banner?.tsAndCs && (
                    <TsAndCs href={banner.tsAndCsLink}>
                      {banner.tsAndCs}
                    </TsAndCs>
                  )}
                </CarouselWrap>
              );
            })}
        </Carousel>
      </OB>
    );
  }
}

GamesCarouselPage.propTypes = {
  getCasinoBanners: PropTypes.func,
  casinoBannerData: PropTypes.array,
  loadGamePage: PropTypes.func,
};

export default GamesCarouselPage;
