import {
  BT,
  BD,
  MD,
  OB,
  CB,
  LB,
  TP,
  W,
  PC,
  CarouselWrap,
} from 'CASINO_CORE__UI/apps/GamesCarouselApp/core__gamesCarousel';
import styled from 'styled-components';
import { GREYS, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S, BREAKPOINT_XS } from 'UI/globals/breakpoints';

const OB_EX = styled(OB)`
  background-color: linear-gradient(
    90deg,
    rgba(4, 9, 50, 1) 0%,
    rgba(0, 0, 0, 1) 50%,
    rgba(4, 9, 50, 1) 100%
  );
`;

const LB_EX = styled(LB)`
  background-color: ${BRAND.primaryD};
  color: ${GREYS.white};
`;

const TP_EX = styled(TP)`
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
  max-width: 1288px;
`;
const CB_OL = styled(CB)`
  background-position: left 25%;
  @media (max-width: ${BREAKPOINT_XS}) {
    background-attachment: fixed;
    background-position: center;
  }
`;
const PC_OL = styled(PC)`
  background-color: ${BRAND.secondary};
  letter-spacing: 0.04em;
  text-transform: uppercase;
`;
const BT_OL = styled(BT)`
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 24px;
  }
`;

const CarouselWrap_OL = styled(CarouselWrap)`
  width: 100%;
`;
export {
  BT_OL as BT,
  BD,
  MD,
  OB_EX as OB,
  CB_OL as CB,
  LB_EX as LB,
  TP_EX as TP,
  W,
  PC_OL as PC,
  CarouselWrap_OL as CarouselWrap,
};
