import React from 'react';
import GamesCarousel from './components/GamesCarousel';
import PropTypes from 'prop-types';
import { FSBTheme } from 'Services/core__fsb-theme';

class GameCarouselApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { theme: this.props?.theme };
  }
  render() {
    return (
      <FSBTheme theme={this.state.theme}>
        <GamesCarousel {...this.props}></GamesCarousel>
      </FSBTheme>
    );
  }
}
GameCarouselApp.propTypes = {
  theme: PropTypes.string,
};
export default GameCarouselApp;
