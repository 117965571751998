import styled, { css } from 'styled-components';

import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import { FONT } from 'UI/globals/colours';

const FD = styled.div`
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 12px;
  ${({ border }) =>
    border &&
    css`
      border: 0px solid ${FONT.lightMute};
    `}
  @media (max-width: ${BREAKPOINT_S}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export { FD };
