import styled from 'styled-components';
import { BRAND, GREYS, FONT, SUPPLEMENTARY } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

// h4 to display banner title
export const BT = styled.h4`
  font-size: 28px;
  font-weight: 700;
  color: ${GREYS.white};
  letter-spacing: normal;
  padding-left: 104px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding-left: 16px;
  }
`;

// P tag to display banner description
export const BD = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${GREYS.white};
  line-height: 20px;
  letter-spacing: normal;
  margin: 0;
  padding-left: 104px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding-left: 16px;
  }
`;

// Main div to display banner info
export const MD = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;

//outer block
export const OB = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.dark ? SUPPLEMENTARY.casino : BRAND.primaryD};
  .slick-track {
    display: flex;
    align-items: center;
  }
  .carousel-slider {
    padding: 0;
    margin: 0;
  }
  .slick-prev {
    left: 8px;
    width: 24px;
    height: 24px;
    @media (max-width: ${BREAKPOINT_S}) {
      display: none !important;
    }
  }
  .slick-next {
    right: 8px;
    width: 24px;
    height: 24px;
    @media (max-width: ${BREAKPOINT_S}) {
      display: none !important;
    }
  }
`;
// Div to display content background image
export const CB = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.imageurl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70%;
`;
// Label to display tags
// colors not available in globals
export const LB = styled.label`
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  background-color: ${({ theme }) =>
    theme.dark ? SUPPLEMENTARY.black : FONT.secondary};
  border-radius: 4px;
  padding: 4px 8px;
  margin: 0 4px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Top level div to display carousel
export const TP = styled.div`
  margin: 0px auto;
  max-width: 1288px;
  cursor: pointer;
  height: 320px;
  @media (max-width: ${BREAKPOINT_S}) {
    height: 200px;
  }
`;
export const W = styled.div`
  margin-block: auto;
  padding: 4px;
  @media (max-width: ${BREAKPOINT_S}) {
    z-index: 1;
    margin-left: 8px;
  }
`;
// Carousel label display
export const CL = styled.div`
  padding-left: 104px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding-left: 16px;
  }
`;
export const CBD = styled.div`
  @media (max-width: ${BREAKPOINT_S}) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 0;
  }
`;
export const TsAndCs = styled.a`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.4);
  text-align: center;
  min-height: 30px;
  padding: 8px;
  color: ${GREYS.white};
  @media (max-width: ${BREAKPOINT_S}) {
    -webkit-text-size-adjust: 100%;
    font-size: 10px;
    text-align: left;
    padding: 8px 16px;
  }
`;
export const CarouselWrap = styled.div`
  position: relative;
`;
export const MI = styled.div`
  margin-top: 48px;

  padding: 0 104px;
  display: flex;
  height: 40px;
  color: ${GREYS.white};
  font-weight: 700;
  align-items: center;
  cursor: pointer;
`;
export const PC = styled.div`
  padding: 0 12px;
  margin-right: 12px;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: ${FONT.secondary};
  border-radius: 24px;
`;
