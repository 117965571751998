import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

const initialState = {
  CasinoCarouselData: {
    casinoBannerData: [],
  },
};

export const gamesCarouselReducer = (state = {}, action) => {
  switch (action.type) {
    // Storing casino banners data in redux store
    case 'GET_CASINO_BANNERS':
      return { ...state, casinoBannerData: action.data };
    default:
      return state;
  }
};
//Combine reducers into a single reducer
export const reducer = combineReducers({
  CasinoCarouselData: gamesCarouselReducer,
});

// Get casino banners data
export const setCasinoBanners = data => ({
  type: 'GET_CASINO_BANNERS',
  data,
});

/**
 * Method to get navigation data
 */
export const getCasinoBanners = () => dispatch => {
  const getAllCasinoBanners = [];
  const getAllAtributes = document.querySelectorAll(
    '[data-app="CasinoBanner"]'
  );
  for (let c = 0; c < getAllAtributes.length; c++) {
    const params = getAllAtributes[c]?.dataset;
    getAllCasinoBanners.push({
      description: params?.description,
      title: params?.title,
      image: params?.image,
      tagsenabled: !!(
        params?.tagsenabled === 'true' || params?.tagsenabled === true
      ),
      tagstodisplay: params?.tagstodisplay?.split(','),
      adlinkurl: params?.adlinkurl.toString(),
      gameId: params?.gameid,
      moreInfoLabel: params?.moreinfolabel,
      moreInfoLink: params?.moreinfolink,
      playLink: params?.playlink,
      playLabel: params?.playlabel,
      olympic: params?.olympic,
      tsAndCs: params?.tsandcs,
      tsAndCsLink: params?.tsandcslink,
      auth: params?.auth,
      visibletohavingsegment: params?.visibletohavingsegment,
    });
  }
  dispatch(setCasinoBanners(getAllCasinoBanners));
};

/**
 * Method to navigate to game page
 * @param { object } data
 */
export const loadGamePage = (url, gameId) => () => {
  let loadUrl;
  if (gameId) {
    loadUrl = window.location.origin + url + '?id=' + gameId;
  } else {
    loadUrl = window.location.origin + url;
  }
  window.location.href = loadUrl;
};

export default () =>
  createStore(reducer, initialState, applyMiddleware(thunkMiddleware));
