import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { connect } from 'react-redux';
import ScDecoder from 'Services/json/core__decoder';
import GameCarouselApp from './component/core__gamesCarouselReact';
import createStore, {
  getCasinoBanners,
  loadGamePage,
} from './core__games-carousel-store';

class GamesCarouselApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) return;

    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    const mapStateToProps = state => ({
      casinoBannerData: state.CasinoCarouselData.casinoBannerData,
      theme: this.appConfig?.theme,
    });

    const mapDispatchToProps = {
      getCasinoBanners: getCasinoBanners,
      loadGamePage: loadGamePage,
    };
    GameCarouselApp.serverFetch = {
      createStore: createStore,
      fetchData: () => dispatch => {
        return new Promise(resolve => {
          dispatch({ type: 'DO_NOTHING' });
          resolve();
        });
      },
    };
    return connect(mapStateToProps, mapDispatchToProps)(GameCarouselApp);
  }
}

export default GamesCarouselApp;
