import EA from 'UI/globals/ExpandArrow';
import { FONT } from 'UI/globals/colours';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

//Method to configure slick carousel settings
export function carouselSettings() {
  return {
    draggable: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
}
const WP = styled.div`
  transform: rotate(90deg);
  background: ${FONT.darkMute};
  position: absolute;
  display: block;
  z-index: 1;
  top: 150px;
  left: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
`;
const WN = styled.div`
  transform: rotate(-90deg);
  background: ${FONT.darkMute};
  position: absolute;
  display: block;
  z-index: 1;
  top: 150px;
  right: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
`;
const AW = styled.div`
  margin-top: 0px;
  position: relative;
`;

//Custom Previous Arrow for the slider
export const PrevArrow = props => {
  return (
    <WP onClick={props.onClick} className="slick-arrow">
      <AW>
        <EA />
      </AW>
    </WP>
  );
};
//Custom Next Arrow for the slider
export const NextArrow = props => {
  return (
    <WN onClick={props.onClick} className="slick-arrow">
      <AW>
        <EA />
      </AW>
    </WN>
  );
};
PrevArrow.propTypes = {
  onClick: PropTypes.func,
};
NextArrow.propTypes = {
  onClick: PropTypes.func,
};
