import EA from 'UI/globals/ExpandArrow';
import { GREYS } from 'UI/globals/colours';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

//Method to configure slick carousel settings
export function carouselSettings({ theme }) {
  return {
    draggable: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    prevArrow: <PrevArrow theme={theme} />,
    nextArrow: <NextArrow theme={theme} />,
  };
}
const WP = styled.div`
  transform: rotate(90deg);
  background-color: ${GREYS.black};
  position: absolute;
  display: block;
  z-index: 1;
  top: 150px;
  left: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  border: none;
`;
const WN = styled.div`
  transform: rotate(-90deg);
  background-color: ${GREYS.black};
  position: absolute;
  display: block;
  z-index: 1;
  top: 150px;
  right: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  border: none;
`;
const AW = styled.div`
  margin-top: 0px;
  position: relative;
`;

//Custom Previous Arrow for the slider
export const PrevArrow = props => {
  return (
    <WP onClick={props.onClick} className="slick-arrow">
      <AW>
        <EA theme={props.theme} />
      </AW>
    </WP>
  );
};
//Custom Next Arrow for the slider
export const NextArrow = props => {
  return (
    <WN onClick={props.onClick} className="slick-arrow">
      <AW>
        <EA theme={props.theme} />
      </AW>
    </WN>
  );
};
PrevArrow.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.string,
};
NextArrow.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.string,
};
